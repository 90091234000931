import { AppEvent } from "./app_events";

export enum ConfigAction {
    RECEIVED = '@@config/RECEIVED'
}

export interface IConfigModel {
    ping_interval: number;
    ping_count: number;
    api_url: string;
}

export class ConfigEvent extends AppEvent<IConfigModel>{}

export class ConfigReceivedEvent extends AppEvent<IConfigModel> {

    constructor(payload: IConfigModel) {
        super(ConfigAction.RECEIVED, payload);
    }
}
