"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WieldingObserver = void 0;
var WieldingObserver = /** @class */ (function () {
    function WieldingObserver(id, callback, type, source) {
        if (source === void 0) { source = 'default'; }
        this.callback = callback;
        this.type = type;
        this.enabled = true;
        this.id = id;
        this.source = source;
    }
    return WieldingObserver;
}());
exports.WieldingObserver = WieldingObserver;
