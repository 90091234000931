export class State {
    private static _instance: State;
    private _apiUrl: string = '';

    private constructor() {
    }

    public static get Instance(): State {
        return this._instance || (this._instance = new this());
    }

    public get ApiUrl(): string {
        return this._apiUrl;
    }

    public set ApiUrl(value: string) {
        this._apiUrl = value;
    }
}





