import { WieldingEvent } from "wielding-events/lib/WieldingEvent";


export enum GlobalAction {
    LOADED = '@@global/LOADED',
}

export enum TimerAction {
    PING = '@@timer/PING',
}

export interface IUnauthorizedModel {
    path: string
}

// export interface ITokenReceivedEvent extends IEvent<TokenAction.RECEIVED, JSendResponse<ITokenModel>> {}
// export interface IEntropyReceivedEvent extends IEvent<EntropyAction.RECEIVED, JSendResponse<IEntropyModel>> {}
// export interface IConfigReceivedEvent extends IEvent<ConfigAction.RECEIVED, JSendResponse<IConfigModel>> {}

export class AppEvent<T> extends WieldingEvent<string, T> {}



