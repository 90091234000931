import {CallApi} from "./support/call_api";
import {JSendResponse} from "wielding-events/lib/JSendResponse";
import {ConfigReceivedEvent, IConfigModel} from "./stores/config_events";
import {appStore} from "./stores/app_store";
import {State} from "./support/state";

let appConfig = {} as IConfigModel;

document.addEventListener('DOMContentLoaded', async () => {
    console.log('DOM loaded');

    await CallApi<JSendResponse<IConfigModel>>({url: "/api/v1/config", method: 'GET'})
        .then((response) => {
            appConfig = response.data;
            appStore.Dispatch(new ConfigReceivedEvent(response.data), true, true);
            State.Instance.ApiUrl = response.data.api_url;
        })
        .catch((error) => {
            console.log(error);
        });

    let configInfo = document.getElementById('config-info');

    configInfo!.innerText = `pi: ${appConfig.ping_interval} pc: ${appConfig.ping_count}`;

    // appStore.AddObserver((event: ConfigEvent) => {
    //     console.log(event);
    // }, ConfigAction.RECEIVED, 'main', true);

    // let myConfig = appStore.GetEventById<IConfigModel>(ConfigAction.RECEIVED);
    //
    // console.log(myConfig);

});

