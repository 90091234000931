"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WieldingEvent = void 0;
var Common_1 = require("./Common");
var WieldingEvent = /** @class */ (function () {
    function WieldingEvent(type, payload) {
        this.type = type;
        this.payload = Common_1.deepCopy(payload);
        this.updated = new Date().getTime();
    }
    return WieldingEvent;
}());
exports.WieldingEvent = WieldingEvent;
