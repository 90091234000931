"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WieldingStoreModel = void 0;
var WieldingStoreModel = /** @class */ (function () {
    function WieldingStoreModel(params) {
        if (params === void 0) { params = {}; }
        this.updated = params.updated;
        this.name = params.name;
    }
    return WieldingStoreModel;
}());
exports.WieldingStoreModel = WieldingStoreModel;
