"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WieldingEventStore = void 0;
var Common_1 = require("./Common");
var WieldingEvent_1 = require("./WieldingEvent");
var WieldingObserver_1 = require("./WieldingObserver");
var WieldingStoreEvent_1 = require("./WieldingStoreEvent");
var WieldingStoreModel_1 = require("./WieldingStoreModel");
var WieldingEventStore = /** @class */ (function () {
    function WieldingEventStore(name) {
        this.observers = {};
        this.events = {};
        this.id = 0;
        this.messages = [];
        this.name = name;
        WieldingEventStore.instance += 1;
        this.loadFromLocal();
    }
    WieldingEventStore.prototype.nameSpace = function (eventType) {
        return this.name + eventType;
    };
    WieldingEventStore.prototype.storeLocal = function (event) {
        var storedEvents = localStorage.getItem(this.nameSpace('@@events'));
        var eventArray = [];
        if (storedEvents) {
            eventArray = JSON.parse(storedEvents);
        }
        var nameSpacedEventType = this.nameSpace(event.type);
        if (eventArray.indexOf(nameSpacedEventType) < 0) {
            eventArray.push(nameSpacedEventType);
            storedEvents = JSON.stringify(eventArray);
            localStorage.setItem(this.nameSpace('@@events'), storedEvents);
        }
        // localStorage.setItem(event.type, btoa(JSON.stringify(event)));
        localStorage.setItem(nameSpacedEventType, JSON.stringify(event));
    };
    WieldingEventStore.prototype.SetDefaultEvent = function (event) {
        var nameSpacedEventType = this.nameSpace(event.type);
        if (!this.events[nameSpacedEventType]) {
            this.storeLocal(event);
            this.events[nameSpacedEventType] = Common_1.deepCopy(event);
        }
    };
    WieldingEventStore.prototype.AddObserver = function (callback, eventName, source, late) {
        if (source === void 0) { source = 'default'; }
        if (late === void 0) { late = false; }
        var nameSpacedEventType = this.nameSpace(eventName);
        if (!this.observers[nameSpacedEventType]) {
            this.observers[nameSpacedEventType] = [];
        }
        this.observers[nameSpacedEventType].unshift(new WieldingObserver_1.WieldingObserver(++this.id, callback, nameSpacedEventType, source + ":" + WieldingEventStore.instance.toString()));
        this.AddMessage("Adding: " + source + ":" + nameSpacedEventType + " id: " + this.observers[nameSpacedEventType][0].id);
        if (late) {
            if (this.events[nameSpacedEventType]) {
                callback(Common_1.deepCopy(this.events[nameSpacedEventType]));
                this.AddMessage("Late Dispatch: " + source + ":" + nameSpacedEventType);
            }
        }
        this.Dispatch(new WieldingEvent_1.WieldingEvent(WieldingStoreEvent_1.WieldingStoreEvent.UPDATED, new WieldingStoreModel_1.WieldingStoreModel({
            name: this.name,
            updated: true
        })), false, false);
        return this.observers[nameSpacedEventType][0];
    };
    WieldingEventStore.prototype.RemoveObserver = function (observer) {
        var continueLooking = true;
        if (observer === undefined) {
            return;
        }
        this.AddMessage("Removing: " + observer.source + " id: " + observer.id);
        if (this.observers[observer.type]) {
            var length_1 = this.observers[observer.type].length;
            for (var idx = 0; idx < length_1 && continueLooking; idx++) {
                if (this.observers[observer.type][idx].id === observer.id) {
                    continueLooking = false;
                    this.observers[observer.type].splice(idx, 1);
                }
            }
            this.Dispatch(new WieldingEvent_1.WieldingEvent(WieldingStoreEvent_1.WieldingStoreEvent.UPDATED, new WieldingStoreModel_1.WieldingStoreModel({
                name: this.name,
                updated: true
            })), false, false);
        }
    };
    WieldingEventStore.prototype.GetEventAge = function (event) {
        var nameSpacedEventType = this.nameSpace(event.type);
        if (this.events[nameSpacedEventType]) {
            if (this.events[nameSpacedEventType].payload.status === 'FAIL') {
                return 9999999999;
            }
            var currentTime = new Date().getTime();
            var cachedEvent = this.events[nameSpacedEventType];
            return currentTime - cachedEvent.updated;
        }
        return 0;
    };
    WieldingEventStore.prototype.GetEvent = function (event) {
        var nameSpacedEventType = this.nameSpace(event.type);
        if (this.events[nameSpacedEventType]) {
            return this.events[nameSpacedEventType];
        }
        return undefined;
    };
    WieldingEventStore.prototype.GetEventById = function (eventId) {
        var nameSpacedEventType = this.nameSpace(eventId);
        if (this.events[nameSpacedEventType]) {
            return this.events[nameSpacedEventType];
        }
        return undefined;
    };
    WieldingEventStore.prototype.GetEventPayload = function (eventId) {
        var nameSpacedEventType = this.nameSpace(eventId);
        if (this.events[nameSpacedEventType]) {
            return this.events[nameSpacedEventType].payload;
        }
        return undefined;
    };
    WieldingEventStore.prototype.ReDispatch = function (event, reason) {
        var foundObserver = false;
        var nameSpacedEventType = this.nameSpace(event.type);
        if (this.events[nameSpacedEventType]) {
            if (this.observers[nameSpacedEventType]) {
                for (var _i = 0, _a = this.observers[nameSpacedEventType]; _i < _a.length; _i++) {
                    var observer = _a[_i];
                    if (observer.enabled) {
                        this.AddMessage("Re-Dispatching (reason: " + reason + ") : " + observer.type + ":" + observer.source + " id: " + observer.id);
                        observer.callback(Common_1.deepCopy(this.events[nameSpacedEventType]));
                        foundObserver = true;
                    }
                }
            }
        }
        if (!foundObserver) {
            this.AddMessage("No Observers: " + nameSpacedEventType);
        }
        return foundObserver;
    };
    WieldingEventStore.prototype.Dispatch = function (event, persistent, notify) {
        if (persistent === void 0) { persistent = true; }
        if (notify === void 0) { notify = true; }
        var foundObserver = false;
        var nameSpacedEventType = this.nameSpace(event.type);
        if (persistent) {
            this.storeLocal(event);
        }
        this.events[nameSpacedEventType] = Common_1.deepCopy(event);
        if (this.observers[nameSpacedEventType]) {
            for (var _i = 0, _a = this.observers[nameSpacedEventType]; _i < _a.length; _i++) {
                var observer = _a[_i];
                if (observer.enabled) {
                    this.AddMessage("Dispatching: " + observer.type + ":" + observer.source + " id: " + observer.id);
                    observer.callback(Common_1.deepCopy(this.events[nameSpacedEventType]));
                    foundObserver = true;
                }
            }
        }
        if (notify) {
            this.Dispatch(new WieldingEvent_1.WieldingEvent(WieldingStoreEvent_1.WieldingStoreEvent.UPDATED, new WieldingStoreModel_1.WieldingStoreModel({
                name: this.name,
                updated: true
            })), false, false);
        }
        if (!foundObserver) {
            this.AddMessage("No Observers: " + nameSpacedEventType);
        }
    };
    WieldingEventStore.prototype.loadFromLocal = function () {
        var storedEvents = localStorage.getItem(this.nameSpace('@@events'));
        if (storedEvents) {
            var eventTypes = JSON.parse(storedEvents);
            try {
                for (var _i = 0, eventTypes_1 = eventTypes; _i < eventTypes_1.length; _i++) {
                    var type = eventTypes_1[_i];
                    var nameSpacedEventType = type;
                    // this.events[type] = JSON.parse(atob(localStorage.getItem(type)!));
                    this.events[nameSpacedEventType] = JSON.parse(localStorage.getItem(nameSpacedEventType));
                }
            }
            catch (e) {
                for (var _a = 0, eventTypes_2 = eventTypes; _a < eventTypes_2.length; _a++) {
                    var type = eventTypes_2[_a];
                    var nameSpacedEventType = type;
                    delete (this.events[nameSpacedEventType]);
                }
                localStorage.clear();
            }
        }
    };
    WieldingEventStore.prototype.AddMessage = function (message) {
        if (this.messages.length > 50) {
            if (this.messages.length > 50) {
                this.messages.splice(0, 1);
            }
            this.messages.push(message);
        }
    };
    WieldingEventStore.instance = 0;
    return WieldingEventStore;
}());
exports.WieldingEventStore = WieldingEventStore;
